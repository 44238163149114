import DataTable from "react-data-table-component";
import { BsPencilSquare, BsTrash3Fill } from "react-icons/bs";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { TablePortofolio, usePortofolioContext } from "../../context/TablePortofolio";
import React from "react";

const Table = () => {
  const { reloadTable, data, get, del, bukaEdit } = usePortofolioContext();
  const [load, setLoad] = useState(true);

  useEffect(() => {
    get();
    setLoad(false);
  }, [reloadTable]);

  const tbl = data.map((item, index) => ({
    no: index + 1,
    id: item.id,
    judul: item.judul,
    foto: item.foto,
  }));

  const columns = [
    {
      name: "No",
      width: "4rem",
      center: true,
      selector: (row) => row.no,
    },
    {
      name: "Judul",
      selector: (row) => row.judul,
    },
    {
      name: "Gambar",
      center: true,
      selector: (row) => <img src={row.foto} className="w-100 my-2" loading="lazy" style={{ maxWidth: "200px" }} alt="" />,
    },
    {
      name: "Aksi",
      button: true,
      center: true,
      cell: (row) => (
        <>
          <button className="btn btn-main p-1 px-2 me-1" onClick={() => bukaEdit(row.judul, row.foto, row.id)}>
            <BsPencilSquare />
          </button>
          <button className="btn btn-danger p-1 px-2" onClick={() => del(row.id)}>
            <BsTrash3Fill />
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      <DataTable columns={columns} data={tbl} progressPending={load} pagination={true} striped />
    </>
  );
};

const ModalPortofolio = () => {
  const {
    setJudul,
    handleFoto,
    judul,
    tipe,
    post,
    update,
    preview,
    errors,
    isDisabled,
    tutupTambah,
    tutupEdit,
    modal,
    bukaTambah,
  } = usePortofolioContext();
  return (
    <>
      <button className="btn btn-main my-3" onClick={bukaTambah}>
        +Tambah
      </button>

      {/* MODAL */}
      <Modal show={modal} onHide={tipe === "Tambah" ? tutupTambah : tutupEdit} scrollable size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{tipe} Portofolio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label htmlFor="judul" className="form-label fw-semibold">
                Judul
              </label>
              <input
                type="text"
                id="judul"
                className={errors.judul ? "form-control is-invalid" : "form-control"}
                placeholder="Masukkan Judul Disini . . ."
                value={judul}
                onChange={(e) => setJudul(e.target.value)}
              />
              {errors.judul && <label className="text-danger mt-1">{errors.judul}</label>}
            </div>
            <div className="mb-3">
              <label htmlFor="foto" className="form-label fw-semibold">
                Foto
              </label>
              <input
                type="file"
                id="foto"
                className={errors.foto ? "form-control is-invalid" : "form-control"}
                accept="image/*"
                onChange={handleFoto}
              />
              {preview ? (
                <div className="text-center">
                  <img src={preview} alt="" className="w-50 mt-3" />
                </div>
              ) : null}
              {errors.foto && <label className="text-danger mt-1">{errors.foto}</label>}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          {tipe === "Tambah" ? (
            <>
              <button className="btn btn-secondary mb-3" onClick={tutupTambah}>
                Tutup
              </button>
              <button className="btn btn-success mb-3" disabled={isDisabled} onClick={post}>
                Simpan
              </button>
            </>
          ) : (
            <>
              <button className="btn btn-secondary mb-3" onClick={tutupEdit}>
                Tutup
              </button>
              <button className="btn btn-success mb-3" disabled={isDisabled} onClick={update}>
                Simpan
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

const PortofolioCRUD = () => {
  return (
    <TablePortofolio>
      <ModalPortofolio />
      <Table />
    </TablePortofolio>
  );
};

export default PortofolioCRUD;
