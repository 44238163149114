import hero from "../assets/images/hero.png";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import React from "react";

function Hero() {
  const scroll = (val) => {
    if (val === 0) {
      window.scrollTo(0, val);
    } else {
      const element = document.getElementById(val);
      if (element) {
        const offset =
          element.getBoundingClientRect().top + window.scrollY - 100;
        window.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <div className="hero-section mt-5" id="beranda">
      <div className="row p-5">
        <div className="col-lg-6 hero-title">
          <h2 className="fw-semibold">
            Buat Website Impian Anda <br /> Bersama Kami, Sekarang 🚀
          </h2>
          <p>
            Kami memiliki layanan pembuatan website & mendesain <br /> aplikasi
            serta website, mari bekerja sama dengan kami
            <br /> menciptakan website yang menakjubkan.
          </p>
          <button
            onClick={() => scroll("tentang")}
            className="btn btn-primary rounded-pill text-white mt-4"
          >
            Selengkapnya <BsFillArrowRightCircleFill className="ms-2" />
          </button>
        </div>
        <div className="col-lg-6 hero-img">
          <img src={hero} alt="" className="w-75" />
        </div>
      </div>
    </div>
  );
}

export default Hero;
