import DataTable from "react-data-table-component";
import { BsPencilSquare, BsTrash3Fill } from "react-icons/bs";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { TableFaq, useFaqContext } from "../../context/TableFaq";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import React from "react";

const Table = () => {
  const { reloadTable, data, get, del, bukaEdit } = useFaqContext();
  const [load, setLoad] = useState(true);

  useEffect(() => {
    get();
    setLoad(false);
  }, [reloadTable]);

  const tbl = data.map((item, index) => ({
    no: index + 1,
    id: item.id,
    judul: item.judul,
    isi: item.isi,
  }));

  const columns = [
    {
      name: "No",
      width: "4rem",
      center: true,
      selector: (row) => row.no,
    },
    {
      name: "Judul",
      width: "20rem",
      wrap: true,
      selector: (row) => row.judul,
    },
    {
      name: "Isi",
      width: "38rem",
      wrap: true,
      selector: (row) => (
        <div
          dangerouslySetInnerHTML={{ __html: row.isi }}
          className="my-2"
        ></div>
      ),
    },
    {
      name: "Aksi",
      button: true,
      center: true,
      cell: (row) => (
        <>
          <button
            className="btn btn-main p-1 px-2 me-1"
            onClick={() => bukaEdit(row.judul, row.isi, row.id)}
          >
            <BsPencilSquare />
          </button>
          <button
            className="btn btn-danger p-1 px-2"
            onClick={() => del(row.id)}
          >
            <BsTrash3Fill />
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={tbl}
        progressPending={load}
        pagination={true}
        striped
      />
    </>
  );
};

const ModalFaq = () => {
  const {
    setJudul,
    setIsi,
    judul,
    isi,
    tipe,
    post,
    update,
    errors,
    isDisabled,
    tutupTambah,
    tutupEdit,
    modal,
    bukaTambah,
  } = useFaqContext();
  return (
    <>
      <button className="btn btn-main my-3" onClick={bukaTambah}>
        +Tambah
      </button>

      {/* MODAL */}
      <Modal
        show={modal}
        onHide={tipe === "Tambah" ? tutupTambah : tutupEdit}
        scrollable
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{tipe} Portofolio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label htmlFor="judul" className="form-label fw-semibold">
                Judul
              </label>
              <input
                type="text"
                id="judul"
                className={
                  errors.judul ? "form-control is-invalid" : "form-control"
                }
                placeholder="Masukkan Judul Disini . . ."
                value={judul}
                onChange={(e) => setJudul(e.target.value)}
              />
              {errors.judul && (
                <label className="text-danger mt-1">{errors.judul}</label>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="isi" className="form-label fw-semibold">
                Isi
              </label>
              <div id="isi">
                <ReactQuill
                  theme="snow"
                  placeholder="Masukkan Isi FAQ Disini . . ."
                  value={isi}
                  onChange={setIsi}
                />
              </div>
              {errors.isi && (
                <label className="text-danger mt-1">{errors.isi}</label>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          {tipe === "Tambah" ? (
            <>
              <button className="btn btn-secondary mb-3" onClick={tutupTambah}>
                Tutup
              </button>
              <button
                className="btn btn-success mb-3"
                disabled={isDisabled}
                onClick={post}
              >
                Simpan
              </button>
            </>
          ) : (
            <>
              <button className="btn btn-secondary mb-3" onClick={tutupEdit}>
                Tutup
              </button>
              <button
                className="btn btn-success mb-3"
                disabled={isDisabled}
                onClick={update}
              >
                Simpan
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

const FaqCRUD = () => {
  return (
    <TableFaq>
      <ModalFaq />
      <Table />
    </TableFaq>
  );
};

export default FaqCRUD;
