import { createContext, useContext, useState } from "react";
import { useAuth } from "./AuthContext";
import axios from "../axiosConfig";
import Swal from "sweetalert2";
import * as Yup from "yup";
import withReactContent from "sweetalert2-react-content";
import React from "react";

const FaqContext = createContext();

export const useFaqContext = () => {
  return useContext(FaqContext);
};

export const TableFaq = ({ children }) => {
  const [reloadTable, setReloadTable] = useState(false);
  const [data, setData] = useState([]);
  const [judul, setJudul] = useState("");
  const [isi, setIsi] = useState("");
  const [errors, setErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(null);
  const [tipe, setTipe] = useState("");
  const tutupTambah = () => {
    setTipe("");
    setModal(false);
  };
  const bukaTambah = () => {
    setTipe("Tambah");
    setModal(true);
  };
  const tutupEdit = () => {
    setTipe("");
    setJudul("");
    setIsi("");
    setId(null);
    setModal(false);
  };
  const bukaEdit = (judul, isi, id) => {
    setTipe("Edit");
    setJudul(judul);
    setIsi(isi);
    setId(id);
    setModal(true);
  };

  const { userToken } = useAuth();
  const Alert = withReactContent(Swal);

  // VALIDASI
  const validasiTambah = Yup.object().shape({
    judul: Yup.string().required("Judul wajib diisi"),
    isi: Yup.string().required("Isi FAQ wajib diisi"),
  });
  const validasiEdit = Yup.object().shape({
    judul: Yup.string().required("Judul wajib diisi"),
    isi: Yup.string().required("Isi FAQ wajib diisi"),
  });

  // GET DATA
  const get = async () => {
    try {
      const response = await axios.get("/api/admin/faq", {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // DELETE DATA
  const del = (id) => {
    Alert.fire({
      title: <p>Yakin Ingin Menghapus Data ?</p>,
      icon: "warning",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/api/admin/faq/delete/${id}`, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          });
          Alert.fire({
            title: <p>Berhasil Menghapus Data</p>,
            icon: "success",
          });
          handleReloadTable();
        } catch (error) {
          Alert.fire({
            title: <p>Gagal Menghapus Data</p>,
            icon: "error",
          });
          console.error("Error fetching data:", error);
        }
      }
    });
  };

  // POST DATA
  const post = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("judul", judul);
    formData.append("isi", isi);

    try {
      await validasiTambah.validate({ judul, isi }, { abortEarly: false });
      setIsDisabled(true);
      setErrors({});
      await axios
        .post("/api/admin/faq/add", formData, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then(() => {
          resetForm();
          Alert.fire({
            title: <p>Tambah Data Berhasil !</p>,
            icon: "success",
          });
          handleReloadTable();
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setIsDisabled(false);
      setErrors(validationErrors);
    }
  };

  // UPDATE DATA
  const update = async (e) => {
    e.preventDefault();
    const formEdit = new FormData();
    formEdit.append("judul", judul);
    formEdit.append("isi", isi);

    try {
      await validasiEdit.validate({ judul, isi }, { abortEarly: false });
      setIsDisabled(true);
      setErrors({});
      axios
        .post(`/api/admin/faq/update/${id}`, formEdit, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then(() => {
          resetForm();
          Alert.fire({
            title: <p>Update Data Berhasil !</p>,
            icon: "success",
          });
          handleReloadTable();
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setIsDisabled(false);
      setErrors(validationErrors);
    }
  };

  // RESET FORM
  const resetForm = () => {
    tipe === "Tambah" ? tutupTambah() : tutupEdit();
    setJudul("");
    setIsi("");
    setErrors({});
    setIsDisabled(false);
  };

  const handleReloadTable = () => {
    setReloadTable((prevValue) => !prevValue);
  };

  return (
    <FaqContext.Provider
      value={{
        reloadTable,
        data,
        userToken,
        errors,
        isDisabled,
        judul,
        isi,
        tipe,
        modal,
        handleReloadTable,
        get,
        del,
        post,
        update,
        setJudul,
        setIsi,
        tutupTambah,
        bukaTambah,
        tutupEdit,
        bukaEdit,
      }}
    >
      {children}
    </FaqContext.Provider>
  );
};
