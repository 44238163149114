import img from "../assets/images/terimakasih.svg";
import { BsWhatsapp } from "react-icons/bs";
import { useBerandaContext } from "../context/BerandaContext";
import React from "react";

export default function Terimakasih() {
  const { kontak } = useBerandaContext();
  const handleOpenNewTab = (link) => {
    window.open(link, "_blank");
  };
  return (
    <div className="mt-section" id="about">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 py-3 wow fadeInUp">
            <h2 className="fw-semibold pl-0">Salam Hangat Dari Kami</h2>
            <p>
              Terima kasih telah mengunjungi website kami, kedatangan anda
              sangat kami nantikan untuk bekerja sama dengan kami.
            </p>
            <button
              className="btn btn-primary rounded-pill mt-5 d-flex align-items-center w-max"
              onClick={() => handleOpenNewTab(kontak["Whatsapp"])}
            >
              <BsWhatsapp className="me-2" /> Kontak Kami
            </button>
          </div>

          <div className="col-lg-6 py-3 wow fadeInRight">
            <div className="img-fluid py-3 text-sm-left text-lg-right">
              <img className="img-fluid" src={img} width="80%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
