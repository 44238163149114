import axios from "../axiosConfig";
import { useEffect, useState } from "react";
import CardPricelist from "./CardPricelist";
import React from "react";

const SectionCard = () => {
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(1);
  const [data, setData] = useState([]);
  const [btn, setBtn] = useState(true);
  const [isDisable, setIsDisable] = useState(false);

  const get = async (page) => {
    try {
      const response = await axios.get(`/api/pricelist?page=${page}`);
      if (current !== 1) {
        setData((prevData) => [...prevData, ...response.data.data]);
      } else {
        setData(response.data.data);
      }
      setIsDisable(false);
      setTotal(response.data.last_page);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleBtn = () => {
    setBtn(!btn);
    setCurrent(current + 1);
    setIsDisable(true);
  };
  useEffect(() => {
    get(current);
  }, [btn]);

  return (
    <>
      <div className="row">
        {data?.map((item) => {
          return (
            <div className="col-lg-4 mt-4" key={item.id}>
              <CardPricelist img={item.foto} title={item.judul} harga={item.harga} diskon={item.diskon} link={item.link_demo} />
            </div>
          );
        })}
      </div>
      {current === total ? null : (
        <div className="d-flex justify-content-center mt-5">
          <button className="btn btn-primary rounded-pill" onClick={handleBtn} disabled={isDisable}>
            Lebih Banyak
          </button>
        </div>
      )}
    </>
  );
};

const Pricelist = () => {
  return (
    <div className="container mt-section" id="pricelist">
      <div className="text-center">
        <h2 className="fw-semibold">Pricelist</h2>
        <p>
          beberapa pricelist yang telah kami siapkan, menawarkan beragam opsi <br />
          untuk layanan kami yang berkualitas tinggi.
        </p>
      </div>
      <SectionCard />
    </div>
  );
};

export default Pricelist;
