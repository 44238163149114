import Card from "react-bootstrap/Card";
import { BsWhatsapp, BsEyeFill } from "react-icons/bs";
import { useBerandaContext } from "../context/BerandaContext";
import React from "react";
export default function CardPricelist({ img, title, harga, diskon, link }) {
  const { kontak } = useBerandaContext();
  function rupiah(angka) {
    angka = parseInt(angka);
    const uang = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(angka);
    return uang;
  }
  const handleOpenNewTab = (link) => {
    window.open(link, "_blank");
  };
  return (
    <Card>
      <Card.Img
        className="object-fit-cover border-bottom"
        style={{ height: "13vi", minHeight: "150px" }}
        variant="top"
        src={img}
      />
      <Card.Body>
        <Card.Title className="fw-semibold">
          {title.length >= 55 ? title.slice(0, 55) + "..." : title}
        </Card.Title>
        <Card.Text>
          {diskon === 0 || diskon === "" || diskon === "0" ? (
            <span className="text-secondary">{rupiah(harga)}</span>
          ) : (
            <>
              <span className="text-decoration-line-through text-secondary">
                {rupiah(harga)}
              </span>
              <br />
              <span className="fw-semibold text-secondary">
                {rupiah(harga - (harga * parseInt(diskon)) / 100)} (diskon{" "}
                {diskon}% )
              </span>
            </>
          )}
          <div className="d-flex justify-content-end mt-2">
            <button
              className="btn btn-secondary mx-1 px-3 py-1 rounded-pill"
              onClick={() => {
                handleOpenNewTab(link);
              }}
            >
              <small className="d-flex align-items-center">
                <BsEyeFill className="me-1" />
                Demo
              </small>
            </button>
            <button
              className="btn btn-success mx-1 px-3 py-1 rounded-pill"
              onClick={() => {
                handleOpenNewTab(kontak["Whatsapp"]);
              }}
            >
              <small className="d-flex align-items-center">
                <BsWhatsapp className="me-1" />
                Pesan
              </small>
            </button>
          </div>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
