import axios from "../axiosConfig";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import React from "react";

const Portofolio = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSrc, setModalSrc] = useState("");
  const [scroll, setScroll] = useState(false);

  const fetchData = async (page) => {
    try {
      const response = await axios.get(`/api/portofolio?page=${page}`);
      setData(response.data.data);
      setTotalPages(response.data.last_page);
      setCurrentPage(response.data.current_page);
      if (scroll) {
        const element = document.getElementById("portofolio");
        const offset = element.getBoundingClientRect().top + window.scrollY - 100;
        window.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
      setScroll(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      fetchData(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      fetchData(currentPage + 1);
    }
  };

  const handleClose = () => {
    setShow(false);
    setModalSrc("");
    setModalTitle("");
  };
  const handleShow = (src, title) => {
    setShow(true);
    setModalSrc(src);
    setModalTitle(title);
  };

  useEffect(() => {
    fetchData(currentPage);
  }, []);

  const renderPageLinks = () => {
    const pageLinks = [];

    if (currentPage - 1 >= 1) {
      pageLinks.push(
        <li className={`page-item`} key={currentPage - 1}>
          <button className="page-link" onClick={() => fetchData(currentPage - 1)}>
            {currentPage - 1}
          </button>
        </li>
      );
    }

    pageLinks.push(
      <li className={`page-item ${currentPage === currentPage ? "active" : ""}`} key={currentPage}>
        <button className="page-link" onClick={() => fetchData(currentPage)}>
          {currentPage}
        </button>
      </li>
    );

    // Mengecek apakah ada halaman setelah `currentPage + 1`
    if (currentPage + 1 <= totalPages) {
      pageLinks.push(
        <li className={`page-item`} key={currentPage + 1}>
          <button className="page-link" onClick={() => fetchData(currentPage + 1)}>
            {currentPage + 1}
          </button>
        </li>
      );
    }

    return pageLinks;
  };

  return (
    <div className="mt-section" id="portofolio">
      <div className="container">
        <div className="row d-flex justify-content-center wow fadeInUp">
          <div className="col-lg-6 text-center">
            <h2 className="fw-semibold">Portofolio Kami</h2>
            <p>
              Kami telah menyelesaikan beberapa website dalam proyek kami, berikut adalah portofolio website yang telah kami buat
            </p>
          </div>
        </div>

        <div className="row mt-2">
          {data.map((item) => {
            return (
              <div className="col-lg-4 py-2 px-2" key={item.id}>
                <div className="card-blog">
                  <div className="header">
                    <div className="post-thumb">
                      <img
                        className="img-fluid w-100 object-fit-cover cursor-pointer"
                        src={item.foto}
                        alt=""
                        onClick={() => handleShow(item.foto, item.judul)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <nav className="row mt-2 justify-content-center">
          <ul className="pagination justify-content-center">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button className="page-link" onClick={handlePrevPage} disabled={currentPage === 1}>
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            {renderPageLinks()}
            <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
              <button className="page-link" onClick={handleNextPage} disabled={currentPage === totalPages}>
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <Modal size="xl" scrollable={true} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img className="w-100" src={modalSrc} alt="" />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Portofolio;
