import React from "react";
import NavbarAdmin from "../../components/NavbarAdmin";
import Hero from "../../components/admin/Hero";
import PricelistCRUD from "../../components/admin/PricelistCRUD";
export default function Pricelist() {
  return (
    <>
      <Hero judul="Pricelist" />
      <NavbarAdmin />
      <div className="container">
        <PricelistCRUD />
      </div>
    </>
  );
}
