import React from "react";
import NavbarAdmin from "../../components/NavbarAdmin";
import Hero from "../../components/admin/Hero";
import CardDaily from "../../components/admin/CardDaily";
import KontakCRUD from "../../components/admin/KontakCRUD";
import {
  BsTrello,
  BsFillFileEarmarkExcelFill,
  BsFillDatabaseFill,
  BsCameraReelsFill,
} from "react-icons/bs";

const Daily = () => {
  return (
    <>
      <Hero judul="Daily" />
      <NavbarAdmin />
      <div className="container mb-5">
        <div className="row mt-4 justify-content-center">
          <div className="col-md-4 mt-2">
            <CardDaily
              title="Trello"
              icon={<BsTrello />}
              link="https://trello.com/b/42Otc4H6/project-tigeweb"
            />
          </div>
          <div className="col-md-4 mt-2">
            <CardDaily
              title="Spreadsheet Keuangan"
              icon={<BsFillFileEarmarkExcelFill />}
              link="https://docs.google.com/spreadsheets/d/1W_WdpA_WxWibR4_3xeJkON6Yba1u0SXmj9yKQqPxIAI/edit?hl=id#gid=977052329"
            />
          </div>
          <div className="col-md-4 mt-2">
            <CardDaily
              title="Spreadsheet Estimasi"
              icon={<BsFillFileEarmarkExcelFill />}
              link="https://docs.google.com/spreadsheets/d/1EaVPIYOzEPuMrjcgbKjuGLY4s7_14CBJi4fPZs4_PbE/edit?hl=id#gid=0"
            />
          </div>
          <div className="col-md-4 mt-2">
            <CardDaily
              title="Konten"
              icon={<BsCameraReelsFill />}
              link="https://docs.google.com/document/d/1abWe0kENH8181q9p-TAhRMtX44DLPQL7_PokK4HuHg8/edit"
            />
          </div>
          <div className="col-md-4 mt-2">
            <CardDaily
              title="Data Center"
              icon={<BsFillDatabaseFill />}
              link="https://drive.google.com/drive/folders/1yBK_KGrH-YtWvZrZK2bkOIi0FTVh7sBS"
            />
          </div>
        </div>
      </div>

      <div className="container">
        <KontakCRUD />
      </div>
    </>
  );
};

export default Daily;
