import Hero from "../components/Hero";
import Layanan from "../components/Layanan";
import Tentang from "../components/Tentang";
import NavbarMain from "../components/NavbarMain";
import Keunggulan from "../components/Keunggulan";
import layanan_web from "../assets/images/layanan-web.png";
import layanan_design from "../assets/images/layanan-design.png";
import Footer from "../components/Footer";
import Terimakasih from "../components/Terimakasih";
import Portofolio from "../components/Portofolio";
import Faq from "../components/Faq";
import "../assets/css/theme.css";
import Pricelist from "../components/Pricelist";
import { BerandaContext } from "../context/BerandaContext";
import React from "react";

function Beranda() {
  return (
    <BerandaContext>
      <NavbarMain />
      <div className="container mt-nav">
        <Hero />

        {/* LAYANAN KAMI */}
        <div className="text-center mt-section" id="layanan">
          <h2 className="fw-semibold">Layanan Kami</h2>
          <p>
            Kami memiliki beberapa layanan yang dapat anda gunakan <br /> sesuai
            kebutuhan anda, berikut adalah layanan kami
          </p>
          <div className="row gap-main justify-content-center mt-4">
            <Layanan src={layanan_web} title="Pembuatan Website">
              Kami siap bekerja sama dengan anda untuk membuat website dengan
              teknologi yang baik pada website anda.
            </Layanan>
            <Layanan src={layanan_design} title="Desain Aplikasi & Website">
              Kami siap bekerja sama dengan anda untuk membuat desain yang
              menarik untuk aplikasi maupun website anda.
            </Layanan>
          </div>
        </div>
      </div>

      <Tentang />
      <Keunggulan />
      <Portofolio />
      <Pricelist />
      <Faq />
      <Terimakasih />
      <Footer />
    </BerandaContext>
  );
}
export default Beranda;
