import React from "react";
function Layanan(props) {
  return (
    <div className="row col-lg-6 layanan">
      <div className="row justify-content-center align-items-center col-3">
        <img src={props.src} alt="" className="w-100 p-0" />
      </div>
      <div className="col-9 ps-3 text-start my-auto">
        <h5 className="fw-semibold">{props.title}</h5>
        <p className="mb-0">{props.children}</p>
      </div>
    </div>
  );
}

export default Layanan;
